<template>
    <div>
        <main class="main-content">
            <h1>The requested page not found</h1>
        </main>
    </div>
</template>

<script>

export default {
    data() {
        return {

        }
    },
    components: {
    }
}
</script>
